@font-face {
  font-family:"Goudar";
  src:url(./fonts/goudar-hl-bold.woff2) format("woff2"),url(./fonts/goudar-hl-bold.woff) format("woff2");
  font-weight:600;
  font-style:normal;
  font-display:swap
}
@font-face {
  font-family:"Basis";
  src:url(./fonts/basis-grotesque-black.woff2) format("woff2"),url(./fonts/basis-grotesque-black.woff) format("woff2");
  font-weight:600;
  font-style:normal;
  font-display:swap
}
@font-face {
  font-family:"Basis";
  src:url(./fonts/basis-grotesque-medium.woff2) format("woff2"),url(./fonts/basis-grotesque-medium.woff) format("woff2");
  font-weight:400;
  font-style:normal;
  font-display:swap
}

@tailwind base;
@tailwind components;

.header {
  background-image: url('./img/mhaw-circles-tr.png');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 400px auto
}

.content {
  background-image: url('./img/mhaw-illustration-factory.png');
  background-position: bottom center;
  background-repeat: repeat-x;
  background-size: auto 300px;
}


@tailwind utilities;